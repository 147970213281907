body {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

@import url("https://hangeul.pstatic.net/hangeul_static/css/nanum-gothic.css");

@font-face {
  font-family: "OTWelcomeBA";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2110@1.0/OTWelcomeBA.woff2")
    format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SUIT-Medium";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_suit@1.0/SUIT-Medium.woff2")
    format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GangwonEdu_OTFBoldA";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2201-2@1.0/GangwonEdu_OTFBoldA.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NanumSquareNeo-Variable";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_11-01@1.0/NanumSquareNeo-Variable.woff2")
    format("woff2");
  font-weight: normal;
  font-style: normal;
}

.welcome-bg {
  position: relative;
}

.vertical-center {
  vertical-align: middle;
}

.image_text_center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.navLogo {
  width: 50px;
}

.bulletin_text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.nav-text {
  vertical-align: middle;
  margin-left: 10px;
  font-size: 15px;
  font-weight: bold;
  color: #05377a;
  text-decoration: none !important;
}

.nav-text,
a:link,
a:visited {
  text-decoration: none;
}

.all-center {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.bg-white {
  background-color: #ffffff;
}

.bg-orange {
  background-color: #9a594b;
}

.bg-ivory {
  background-color: #f2f2f2;
}

.bg-light-purple {
  background-color: #4e4d60;
}

.bg-footer {
  background-color: #1d1e23;
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "SUIT-Medium";
  font-size: 16px;
  color: white;
}

.onnuri_footer {
  width: 140px;
}

.contactName {
  letter-spacing: 1px;
}

.contactLink {
  color: white;
  transition: all 0.35s ease-in;
}

.contactLink:hover {
  color: #595a5c;
}

.contactCopy {
  font-size: 14px;
  margin-bottom: 10px;
}

.contactText {
  line-height: 30px;
  margin-top: 15px;
}

.directionBlock {
  height: 422px;
}

.serviceBlock {
  height: 781px;
}

.welcomeBlock {
  height: 794px;
}

.missionBlock {
  height: 240px;
  color: #3f4359;
  text-align: center;
}

.fixed_pic {
  height: auto;
  width: 100%;
  position: fixed;
  z-index: -1;
}

.bulletin_pic {
  padding: 0;
  display: block;
  margin: 0 auto;
  height: 90%;
  width: 100vw;
  max-width: 100%;
  -webkit-filter: brightness(50%);
}

.mainLogo {
  width: 48px;
}

.full_pic {
  width: 100%;
  height: 794px;
  -webkit-filter: brightness(80%);
}

.service_pic_1 {
  width: 100%;
  height: 781px;
  -webkit-filter: brightness(80%);
}

.service_pic_2 {
  display: none;
}

.mainText {
  margin-top: 850px;
}

.logo-text {
  vertical-align: middle;
  margin-left: 10px;
  font-size: 25px;
  font-weight: bold;
  color: #05377a;
}

.welcomeText {
  font-family: "OTWelcomeBA";
  color: white;
  font-size: 100px;
  line-height: 25px;
}

.welcomeText .main {
  line-height: 130px;
  letter-spacing: 5px;
}

.welcomeText span {
  font-size: 30px;
  letter-spacing: 4px;
  line-height: 25px;
}

.white-line {
  margin: 1rem 0;
  color: white;
  border: 0;
  border-top: 11px solid;
  width: 100px;
}

.grey-line {
  margin: 0.2rem 0;
  color: #b0b2b8;
  border: 0;
  border-top: 3px solid;
  width: 23px;
}

.missionEng {
  font-family: "OTWelcomeBA";
  font-weight: bolder;
  font-size: 45px;
  display: inline-block;
  text-align: left;
  letter-spacing: 4px;
  line-height: 55px;
}

.missionKor {
  display: inline-block;
  text-align: left;
  font-family: "SUIT-Medium";
  font-size: 20px;
  vertical-align: middle;
}

.missionKor span {
  font-size: 45px;
}

.serviceTitle {
  font-family: "OTWelcomeBA";
  font-weight: bolder;
  font-size: 45px;
  color: white;
  letter-spacing: 6px;
  line-height: 55px;
  margin-top: 95px;
  margin-left: 6rem;
}

.serviceTitle span {
  font-family: "SUIT-Medium";
  letter-spacing: 5px;
}

.service-bg {
  top: 0;
  left: 0;
  height: 380px;
}

.service-bg-2 {
  top: 0;
  left: 0;
  height: 401px;
}

.service-bg-g1 {
  background-color: #735481c4;
}

.service-bg-g2 {
  background-color: #5c4565;
}

.service-bg-g3 {
  background-color: #6a4e77;
}

.service-bg-g4 {
  background-color: #846d8d;
}

.container {
  max-width: 1100px !important;
}

.serviceSubtitle {
  font-family: "SUIT-Medium";
  font-weight: bolder;
  font-size: 30px;
  color: white;
  letter-spacing: 4px;
  margin-bottom: 2rem;
}

.serviceDesc {
  font-family: "SUIT-Medium";
  font-weight: bolder;
  font-size: 20px;
  color: white;
}

.serviceDesc span {
  text-decoration: underline;
}

.emailLink {
  color: white;
  -moz-transition: all 0.35s ease-in;
  -o-transition: all 0.35s ease-in;
  -webkit-transition: all 0.35s ease-in;
  transition: all 0.35s ease-in;
}

.emailLink:hover {
  color: white;
  text-shadow: 2px 2px black;
}

.donate_gif {
  width: 72px;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.donate_text {
  color: rgb(176, 178, 184);
}

.donateMain {
  font-family: "OTWelcomeBA";
  font-size: 50px;
}

.donateSub {
  font-family: "SUIT-Medium";
  font-size: 30px;
}

.donateBtn {
  font-family: "OTWelcomeBA";
  font-size: 22px;
  font-weight: bolder;
  text-decoration: none;
  letter-spacing: 5px;
  padding: 20px 17px;
  border: 3px solid #3d455c;
  color: #3d455c;
  box-shadow: 7px 7px #3d455c;
}

.bulletinBtn {
  font-family: "GangwonEdu_OTFBoldA";
  font-size: 22px;
  font-weight: bolder;
  text-decoration: none;
  letter-spacing: 5px;
  padding: 20px 17px;
  border: 3px solid #3c3c3c;
  background-color: #dfcece;
  color: #2d2d2d;
  box-shadow: 7px 7px #3c3c3c;
}

.bulletinBtn:hover {
  background-color: #323232;
  color: #eaeaea;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  box-shadow: 7px 7px #3c3c3c;
}

.donateBtn:hover {
  background-color: #626475;
  color: #eaeaea;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  box-shadow: 7px 7px #3d455c;
}

.directionTitle {
  color: white;
  font-family: "OTWelcomeBA";
  font-size: 30px;
  letter-spacing: 5px;
}

.directionDesc {
  color: white;
  font-family: "SUIT-Medium";
  font-size: 16px;
}

.directionBox {
  margin-top: 4rem;
  margin-left: 3rem;
}

.gmap {
  box-shadow: 0 15px 50px rgb(0 0 0 / 0.2);
}

.gmap:hover {
  filter: brightness(50%);
  transition: all 0.6s ease;
}

.address {
  color: white;
}
.full_pic_2 {
  display: none;
}

.gmap_act {
  width: 550px;
  max-width: 100%;
  height: auto;
}

.accessContainer {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 1000px;
}

.accessBlock {
  display: flex;
  justify-content: space-evenly; /* center the flex items horizontally */
  align-items: center; /* center the flex items vertically */
  height: 300px;
}

.accessImg {
  width: 65px;
  z-index: 1;
}

.sizeMed {
  width: 45px;
}

.circle {
  height: 130px;
  width: 130px;
  background-color: #f2f2f2;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  transform: translateZ(0px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  transition: background-color 0.5s ease;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.15);
}

.overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
}

.parkingLocation {
  color: white;
  transition: all 0.35s ease-in;
}

.parkingLocation:hover {
  color: #6e2f0d;
}

.accessText {
  font-family: "SUIT-Medium";
  text-align: center;
  margin-top: 1.5rem;
  font-size: 18px;
  font-weight: bolder;
}

.accessHover {
  font-family: "SUIT-Medium";
  color: white;
  font-size: 16px;
  font-weight: bolder;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: rgba(46, 43, 43, 0.8);
  color: white;
  z-index: 2;
  transition: opacity 0.5s ease; /* Add transition for opacity */
}

.circle:hover {
  background-color: #ffffff; /* Faded grey color on hover */
}

.circle:hover .accessHover {
  opacity: 1; /* Show the '주보' text on hover */
}

.login-background {
  display: flex;
  justify-content: center; /* center horizontally */
  align-items: center; /* center vertically */
  height: 100vh; /* make the background full height */
  background-color: #081013;
}

.loginBtn {
  background-color: #2e2e2e !important;
  border-color: #000000 !important;
  transition: all 0.5s ease !important;
}

.loginBtn:hover {
  color: rgb(221, 221, 221) !important;
  background-color: #474747 !important;
}

.loginFail {
  font-size: 13px;
}

.sidebar {
  height: 100%;
  background-color: #2f4050;
  width: 250px;
}

.adminPage > main {
  flex-grow: 1;
}

.sidebarList {
  height: auto;
  padding: 0;
  width: 100%;
}

.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.logoSide {
  width: 150px;
}

.sidebarList .sidebarRow {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  list-style-type: none;
  margin: 0%;
  flex-direction: row;
  color: white;
  justify-content: center;
}

.sidebarList .sidebarRow:hover {
  cursor: pointer;
  background-color: #293846;
}

.sidebarList .sidebarRow #icon {
  height: 100%;
  display: flex;
  align-items: center; /* This will vertically center the content of #icon */
  justify-content: center; /* This will horizontally center the content of #icon */
  flex: 30%;
  padding-right: 10px;
}

.sidebarRow #title {
  height: 100%;
  flex: 70%;
}

.sidebarList .sidebarRow a {
  text-decoration: none;
  color: inherit;
}

.sidebarList .sidebarRow a #icon,
.sidebarList .sidebarRow a #title {
  display: inline-block;
}

.adminPage {
  /* background-color: #081013; */
  display: flex;
  height: 100vh;
  font-family: "Nanum Gothic", sans-serif;
  background-color: #ffffff;
}

/* Upload Jubo page */
.uploadPage {
  padding-left: 4rem;
  padding-top: 4rem;
}

.uploadRef {
  font-size: 16px;
}

.uploadTable {
  font-size: 16px;
  margin-right: 4rem;
}

.uploadTable #iconLive,
.uploadTable #iconDelete,
.uploadTable #date,
.uploadTable #live,
.uploadTable #delete,
.uploadTable #dateVal {
  text-align: center;
}

.uploadTable td,
.uploadTable th {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}

.uploadTable #name {
  width: 70%;
}

.uploadTable #date {
  width: 15%;
}

.uploadTable #live {
  width: 15%;
}

.uploadTable #delete {
  width: 10%;
}

/* Upload Modal */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.35);
  transition: opacity 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px); /* For Safari */
}

.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgb(255, 255, 255, 0.9);
  padding: 20px;
  max-width: 550px;
  height: 250px;
  width: 90%;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  margin-bottom: 25rem;
}

.modalForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.modalForm input[type="file"] {
  display: none; /* Hide the default file input */
}

.selectedFileWrapper {
  display: flex;
  align-items: center; /* Aligns items vertically in the center */
  height: 20px; /* Adjust this value to create more or less space */
  margin-top: 0.5rem;
  font-family: "Courier New", Courier, monospace;
  font-size: 16px;
  font-weight: bold;
}

.liveModalContent {
  height: 240px;
}

.deleteModalContent {
  height: 320px;
}

.currentJubo {
  margin-right: 1rem;
}

.currentJubo a {
  color: white;
}

.uploadBtn a {
  color: white;
}

.modalForm .fileUploadLabel {
  padding: 10px;
  background-color: #233136;
  color: white;
  cursor: pointer;
  font-size: 16px;
  display: inline-flex; /* Make the label a flex container */
  align-items: center; /* Align items vertically */
}

.fileUploadContent {
  display: flex;
  align-items: center;
}

.fileUploadContent .MuiSvgIcon-root {
  /* You may need to adjust the class name depending on your setup */
  margin-right: 10px;
}

.modalForm input,
.modalForm button,
.modalForm p {
  margin-top: 10px;
  margin-bottom: 10px;
}

.loadingIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0.3);
  height: 100%;
}

.fade-transition {
  font-size: 13px;
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.fade-transition.fade-exit {
  opacity: 0;
}

.fade-transition.fade-exit-active {
  opacity: 0;
}

.emptySelect {
  border: 0px;
  height: 20px;
}

.selectedFileWrapper {
  padding: 5px;
  margin-bottom: 1rem;
}

.selectedFile {
  margin: 0;
}

.modalTitle {
  text-align: left;
  width: 100%;
  margin-left: 2rem;
  font-weight: bolder;
}

.submitBtn {
  margin-right: 2rem;
}

.deletePrompt {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  margin-right: 275px;
}

.deleteText {
  font-weight: bold;
  letter-spacing: 0.5px;
}

.buttonContainer {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-left: 2rem;
  margin-top: 1rem;
}

.newLink {
  display: flex;
  align-items: center;
}

.displayLinks {
  display: flex;
  align-items: center;
}

.linkBtn {
  margin-left: 1rem;
}

.linkEditBtn {
  margin-right: 1rem;
}

.linkText {
  margin-top: 1rem;
}

/* Popup Styles */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
}

.popup-content {
  position: relative;
  width: 80%; /* You can adjust the width as needed */
  max-width: 700px; /* Maximum width of the popup */
  height: 685px;
  background-image: url("https://i.imgur.com/nzCcWA6.png"); /* Replace with your image path */
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  line-height: 1;
  font-family: "SUIT-Medium";
  background-position: center 5px;
}

.popup-close {
  font-family: "OTWelcomeBA";
  align-self: flex-end;
  font-weight: bolder;
  font-size: 1.5em;
  background: none;
  border: none;
  cursor: pointer;
  margin-bottom: 300px;
}

.popup-text {
  background: rgba(0, 0, 0, 0.06);
  color: #000000; /* Adjust text color as needed */
  text-align: center;
  margin-top: 20px; /* Adjust spacing as needed */
  padding: 0px 20px;
  padding-top: 15px;
}

.popupDateText {
  font-weight: bolder;
  font-size: 18px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.popupDate {
  margin-left: 10px;
  margin-right: 10px;
}

@media (max-width: 1000px) {
  .serviceBlock .row {
    display: block;
  }

  .serviceBlock {
    height: 1295px;
  }

  .service-bg {
    height: 180px;
  }

  .serviceTitle {
    font-size: 35px;
    margin-top: -100px;
    padding-top: 1.5rem;
    line-height: 45px;
    margin-left: 0rem;
    text-align: center;
  }

  .serviceBlock .container {
    --bs-gutter-x: 0rem !important;
  }

  .service-bg-g2,
  .service-bg-g3,
  .service-bg-g4 {
    text-align: cetner;
  }

  .serviceSubtitle {
    font-size: 28px;
    padding-top: 1rem;
    margin-bottom: 0.5rem;
  }

  .serviceDesc {
    padding-bottom: 1rem;
  }

  .service_pic_1 {
    display: none;
  }

  .service_pic_2 {
    display: block;
    height: 1170px;
    width: 100%;
    -webkit-filter: brightness(50%);
  }

  .image_text_center {
    top: 40%;
  }

  .row {
    --bs-gutter-x: 0rem !important;
  }

  .gmap_act {
    width: 500px;
  }

  .accessContainer {
    width: 100vw;
  }

  .circle {
    width: 120px;
    height: 120px;
  }

  .accessImg {
    width: 55px;
  }

  .sizeMed {
    width: 40px;
  }
}

@media (max-width: 900px) {
  .welcomeText .main {
    font-size: 75px;
    line-height: 110px;
  }

  .full_pic_1 {
    display: none;
  }

  .full_pic_2 {
    display: block;
    width: max-content;
    height: auto;
    position: fixed;
    z-index: -1;
  }

  .donateMain {
    font-size: 45px;
  }

  .gmap_act {
    width: 430px;
  }
}

@media (max-width: 735px) {
  .missionBlock {
    text-align: left;
    height: 420px;
  }

  .missionBlock .row {
    display: block;
  }

  .missionContainer {
    margin-left: 5rem;
  }

  .missionKor {
    margin-top: 2rem;
    margin-left: 3rem;
  }

  .missionEng {
    font-size: 40px;
    margin-left: 3rem;
  }

  .missionKor span {
    font-size: 40px;
  }

  .missionKorDesc {
    font-size: 16px;
  }

  .donateMain {
    font-size: 35px;
  }

  .donateSub {
    font-size: 25px;
  }

  .directionBlock .row {
    display: block;
  }

  .directionBlock {
    margin-top: -64px;
    height: 575px;
    text-align: center;
  }

  .directionTitle {
    padding-top: 2rem;
  }

  .gmap_act {
    width: 450px;
  }

  .directionBox {
    margin-top: 1.5rem;
    margin-left: 0rem;
  }

  .gmap {
    margin-left: auto;
    margin-right: auto;
  }

  .contactBlock {
    height: 334px;
  }
}

@media (max-width: 615px) {
  .contactBlock {
    height: 358px;
  }
}

/* Responsive Styles for Mobile Devices */
@media (max-width: 600px) {
  .popup-content {
    width: 95%; /* Increase width on smaller screens */
    max-width: none; /* Remove max-width restriction */
    height: 560px; /* Let the height adjust based on content */
    padding: 1px; /* Reduce padding */
  }

  .popup-close {
    margin-top: 20px;
    margin-right: 20px;
    margin-bottom: 20px; /* Adjust the margin for the close button */
    font-size: 1.8em; /* Slightly larger close button for easier interaction */
  }

  .popup-text {
    margin-top: 245px;
    font-size: 13px; /* Reduce font size for readability */
    padding: 10px; /* Adjust padding */
    line-height: 0.95;
  }

  .popupDateText {
    font-size: 14px; /* Adjust font size for date text */
  }

  /* Adjust other styles as needed for better mobile responsiveness */
}

@media (max-width: 575px) {
  .contactBlock {
    height: 459px;
  }
}

/* Responsive Styles for Mobile Devices */

@media (max-width: 530px) {
  .welcomeText .main {
    font-size: 50px;
    line-height: 80px;
  }

  .donateMain {
    font-size: 30px;
  }
  .welcomeBlock {
    height: 690px;
  }
}

@media (max-width: 500px) {
  .accessBlock {
    flex-direction: column;
    align-items: center;
    height: 600px;
  }

  .directionBlock {
    margin-top: -24px;
  }
}

@media (max-width: 490px) {
  .gmap_act {
    width: 80vw;
  }
}

@media (max-width: 488px) {
  .popup-content {
    width: 95%; /* Increase width on smaller screens */
    max-width: none; /* Remove max-width restriction */
    height: 550px; /* Let the height adjust based on content */
    padding: 1px; /* Reduce padding */
  }

  .popup-close {
    margin-top: 20px;
    margin-right: 20px;
    margin-bottom: 20px; /* Adjust the margin for the close button */
    font-size: 1.8em; /* Slightly larger close button for easier interaction */
  }

  .popup-text {
    margin-top: 210px;
    font-size: 13px; /* Reduce font size for readability */
    padding: 10px; /* Adjust padding */
    line-height: 0.95;
    padding-top: 15px;
  }

  .popupDate {
    margin-left: 3px;
    margin-right: 3px;
  }

  .popupDateText {
    font-size: 13px; /* Adjust font size for date text */
  }

  /* Adjust other styles as needed for better mobile responsiveness */
}

@media (max-width: 400px) {
  .welcomeText .main {
    font-size: 40px;
    line-height: 1.5;
  }

  .donateMain {
    font-size: 28px;
  }

  .donateBtn {
    font-size: 4vw;
  }
}
